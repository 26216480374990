import {TableRow} from "@mui/material";
import {Companies} from "../../../types/companies";
import styles from "./index.module.scss"
import {ReactNode} from "react";
import classNames from "classnames";

const isElementClickable = (element: HTMLElement): boolean => {
  const clickableTags = ['BUTTON', 'A', 'INPUT'];
  if (clickableTags.includes(element.tagName)) {
    return true;
  }
  if (element.getAttribute('onclick') !== null) {
    return true;
  }
  // Check if there is a React onClick event handler
  const reactProps = Object.keys(element).filter(prop => prop.startsWith('__reactEventHandlers$'));
  return reactProps.length > 0 && (element as any)[reactProps[0]].onClick;
};

const CompanyRow = ({
  company,
  key,
  children,
  clickable=true,
  highlighted=false,
}: {
  company: Companies | string,
  key: string,
  children: ReactNode,
  clickable?: boolean,
  highlighted?: boolean,
}) => {
  const companyId = (typeof company === "object" && "id" in company) ? company.id : company

  return (
    <TableRow
      key={key}
      className={classNames(styles.row, {
        [styles.clickable]: clickable,
        [styles.highlighted]: highlighted,
      })}
      onClick={(e) => {
        if (clickable) {
          if (e.target && isElementClickable(e.target as HTMLElement)) {
            e.stopPropagation()
          } else {
            window.location.assign(`/companies/${companyId}`)
          }
        }
      }}
    >
      {children}
    </TableRow>
  )
}

export default CompanyRow
