import ModalComponents from "../../components/Modal";
import {Button, IconButton, TextField, Tooltip} from "@mui/material";
import * as React from "react";
import {Screener} from "../../types/search";
import {useImmer} from "use-immer";
import {createContext, ReactNode, useContext, useEffect} from "react";
import axios from "axios";
import {ToastContext} from "../../providers/ToastProvider";


type EditScreenerModalContextValue = {
  openUpdateScreener: (screener: Screener, callbackFn?: (newName: string) => void) => void,
}

export const EditScreenerModalContext = createContext<EditScreenerModalContextValue>({
  openUpdateScreener: (screener: Screener, callbackFn?: (newName: string) => void) => {},
});

const EditScreenerModal = ({
  open,
  onClose,
  screener,
  callbackFn,
}: {
  open: boolean;
  onClose: () => void,
  screener?: Screener;
  callbackFn: (newName: string) => void,
}) => {
  const {setSuccess, setError} = useContext(ToastContext)

  useEffect(() => {
    if (screener) {
      setState((state) => {
        state.updatedScreenerName = screener.name
      })
    }
  }, [screener?.name])

  const defaultState = {
    updatedScreenerName: "",
    requestPending: false,
  }

  const [state, setState] = useImmer<{
    updatedScreenerName: string
    requestPending: boolean
  }>(defaultState);

  const onSubmit = (callbackFn?: (name: string) => void) => {
    setState((state) => {
      state.requestPending = true
    });
    axios({
      method: "PUT",
      url: `/api/v1/screeners/${screener?.id}`,
      withCredentials: true,
      data: {
        name: state.updatedScreenerName,
      }
    }).then((response) => {
      if (callbackFn) {
        callbackFn(state.updatedScreenerName)
      }
      setSuccess("Screener updated successfully")
    }).catch((error) => {
      console.error(error)
      setError("Error updating screener")
    }).finally(() => {
      onClose()
    })
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={() => {
        onClose()
        setState(defaultState)
      }}
    >
      <ModalComponents.ModalPage
        title={`Update "${screener?.name}"`}
        body={
          <TextField
            autoFocus
            value={state.updatedScreenerName}
            label="Name"
            sx={{
              marginTop: "1rem",
            }}
            onChange={(e) => {
              setState((state) => {
                state.updatedScreenerName = e.target.value
              })
            }}
            fullWidth
          />
        }
        buttons={
          <>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={state.updatedScreenerName === screener?.name || state.updatedScreenerName.length === 0}
              onClick={() => {
                onSubmit(callbackFn)
                onClose()
              }}
            >
              Update
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  )
}


const EditScreenerModalProvider = ({
  children,
}: {
  children: ReactNode,
}) => {
  const defaultState = {
    open: false,
    callbackFn: () => {},
    requestPending: false,
    screener: undefined,
    updatedScreenerName: ""
  }

  const [state, setState] = useImmer<{
    open: boolean,
    callbackFn: (newName: string) => void
    requestPending: boolean,
    screener?: Screener,
    updatedScreenerName: string
  }>(defaultState);

  const onClose = () => {
    setState(defaultState)
  }

  const noneCallbackFn = (newName: string) => {}

  const context = {
    openUpdateScreener: (screener: Screener, callbackFn?: (newName: string) => void) => {
      setState((state) => {
        state.screener = screener
        state.open = true
        state.callbackFn = callbackFn || noneCallbackFn
      })
    }
  }

  return (
    <EditScreenerModalContext.Provider value={context}>
      {children}
      <EditScreenerModal
        open={state.open}
        screener={state.screener}
        onClose={onClose}
        callbackFn={state.callbackFn}
      />
    </EditScreenerModalContext.Provider>
  )
}


export default EditScreenerModalProvider
