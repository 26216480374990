import React, {lazy, Suspense, useContext, useEffect} from "react";
import "./App.css";
import Nav from "./components/Nav";
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {SessionContext} from "./providers/SessionProvider";
import {Admin, Protected} from "./components/AuthRoute";
import Layout from "./components/Layouts";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import {themeOptions} from "./providers/ThemeProvider";

import { enableMapSet } from 'immer'
import store from "./store";
import AllProvider from "./providers/AllProvider";

import styles from "./index.module.scss"

const WatchlistsNew = lazy(() => import("./pages/watchlists/new"));
const WatchlistsShow = lazy(() => import("./pages/watchlists/show"));
const WatchlistsIndex = lazy(() => import("./pages/watchlists/index"));
const WatchlistsAdd = lazy(() => import("./pages/watchlists/add"));
const CompaniesPage = lazy(() => import("./pages/companies"));
const Login = lazy(() => import("./pages/login"));
const SearchesIndex = lazy(() => import("./pages/searches/index"));
const SearchesShow = lazy(() => import("./pages/searches/show"));
const SearchesNew = lazy(() => import("./pages/searches/new"));
const SearchIterationsShow = lazy(() => import("./pages/iterations/show"));
const SearchIterationsNew = lazy(() => import("./pages/iterations/new"));
const UsersIndex = lazy(() => import("./pages/admin/users/index"));
const AccountsIndex = lazy(() => import("./pages/accounts"));
const ScreenersIndex = lazy(() => import("./pages/screeners/index"));
const ScreenersShow = lazy(() => import("./pages/screeners/show"));
const ScreenersAdd = lazy(() => import("./pages/screeners/add"));
const Forgot = lazy(() => import("./pages/forgot"));
const Reset = lazy(() => import("./pages/reset"));


declare global {
  interface Window { Appcues: any; }
}

const AppcuesIdentity = () => {
  const session = useContext(SessionContext);

  if (session.exists && session.user?.email) {
    window.Appcues.identify(
      session.user.email,
    );
  }

  return null
}

const App = () => {
  enableMapSet()

  useEffect(() => {
    window.Appcues.page();
  })

  return (
    <Provider store={store}>
      <ThemeProvider theme={themeOptions}>
        <CssBaseline />
        <AllProvider>
          <AppcuesIdentity />
          <div className={styles.nonmobile}>
            <Router>
              <Nav />
              <Layout>
                {/* TODO: update this to a spinner */}
                <Suspense fallback={<></>}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Navigate to="/screeners" />
                      }
                    />
                    <Route
                      path="/searches"
                      element={
                        <Protected>
                          <SearchesIndex />
                        </Protected>
                      }
                    />
                    <Route
                      path="/searches/new"
                      element={
                        <Protected>
                          <SearchesNew />
                        </Protected>
                      }
                    />
                    <Route
                      path="/searches/:searchId"
                      element={
                        <Protected>
                          <SearchesShow />
                        </Protected>
                      }
                    />
                    <Route
                      path="/searches/:searchId/iterations/new"
                      element={
                        <Protected>
                          <SearchIterationsNew />
                        </Protected>
                      }
                    />
                    <Route
                      path="/searches/:searchId/iterations/:iterationId"
                      element={
                        <Protected>
                          <SearchIterationsShow />
                        </Protected>
                      }
                    />
                    <Route
                      path="/watchlists"
                      element={
                        <Protected>
                          <WatchlistsIndex />
                        </Protected>
                      }
                    />
                    <Route
                      path="/watchlists/:watchlistId"
                      element={
                        <Protected>
                          <WatchlistsShow />
                        </Protected>
                      }
                    />
                    <Route
                      path="/watchlists/new"
                      element={
                        <Protected>
                          <WatchlistsNew />
                        </Protected>
                      }
                    />
                    <Route
                      path="/watchlists/add"
                      element={
                        <Protected>
                          <WatchlistsAdd />
                        </Protected>
                      }
                    />
                    <Route
                      path="/screeners"
                      element={
                        <Protected>
                          <ScreenersIndex />
                        </Protected>
                      }
                    />
                    <Route
                      path="/screeners/:screenerId"
                      element={
                        <Protected>
                          <ScreenersShow />
                        </Protected>
                      }
                    />
                    <Route
                      path="/screeners/:screenerId/add"
                      element={
                        <Protected>
                          <ScreenersAdd />
                        </Protected>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <Admin>
                          <UsersIndex />
                        </Admin>
                      }
                    />
                    <Route
                      path="/accounts"
                      element={
                        <Protected>
                          <AccountsIndex />
                        </Protected>
                      }
                    />
                    <Route
                      path="/companies/:companyId"
                      element={
                        <Protected>
                          <CompaniesPage />
                        </Protected>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot" element={<Forgot />} />
                    <Route path="/reset/:forgotPasswordId" element={<Reset />} />
                  </Routes>
                </Suspense>
              </Layout>
            </Router>
          </div>
          <div className={styles.mobile}>
            <Nav />
            <Box
              sx={{
                padding: "1rem",
                whiteSpace: "normal",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                flexGrow: "1",
              }}
            >
              Please use a desktop or a tablet to interact with the platform.
            </Box>
          </div>
        </AllProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
