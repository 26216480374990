import {Companies} from "./companies";
import {Dayjs} from "dayjs";

export type User = {
  id: string
  firstName: string,
  lastName: string,
  email: string
  isAdmin: boolean
  deletedAt?: string
  lastLogin?: string
  shownWelcomeAt?: string
  lastActivity?: string
  numLists?: number,
  numIterations?: number,
  numScreeners?: number,
  organization: Organization,
  optedIn: boolean,
};

export const DefaultOrganization = {
  id: "",
  name: "",
  type: "",
  credits: {
    balance: 0,
    granted: 0,
    spent: 0,
    monthlyRemoves: 0,
  },
  billingInformation: {
    id: "",
    billingEmail: "",
    // stripeCustomerId: "",
    // stripeSubscriptionId: "",
    status: "",
    creditCard: {
      brand: "",
      last4: "",
      expirationMonth: "",
      expirationYear: "",
    },
    product: {
      id: "",
      name: "",
    }
  },
  limits: {
    lists: 0,
    searches: 0,
    analyses: 0,
    screeners: 0,
    companiesPerScreener: 0,
    deletedScreeners: 0,
  },
  resources: {
    screeners: 0,
  },
  intentTypes: []
}

export const DefaultUser: User = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  isAdmin: false,
  organization: DefaultOrganization,
  optedIn: false,
}

export type IntentTypes = {
  id: string;
  type: string;
}

export type Organization = {
  id: string;
  name: string;
  type: string;
  credits: OrganizationCredits;
  resources: Resources;
  limits: Limits;
  billingInformation: BillingInformation;
  intentTypes: IntentTypes[];
}

export type Resources = {
  screeners: number,
}

export type Limits = {
  lists: number,
  searches: number,
  screeners: number,
  companiesPerScreener: number,
  deletedScreeners: number,
}

export type IntentPagination = {
  numEntries: number,
  numPages: number,
}

export type BillingInformation = {
  id: string;
  billingEmail: string;
  // stripeCustomerId: string;
  // stripeSubscriptionId: string;
  status: string;
  creditCard: {
    brand: string;
    last4: string;
    expirationMonth: string;
    expirationYear: string;
  };
  product: {
    id: string,
    name: string,
  }
}

export type OrganizationCredits = {
  spent: number;
  granted: number;
  balance: number;
  monthlyRemoves: number;
}

export type Search = {
  id: string;
  name: string;
  iterations: SearchIteration[];
  user: User;
  createdAt: string;
};

export type Stage = {
  id: string;
  stage: string;
  message: string;
  startedAt: string;
  endedAt: string;
  duration: string;
}

export type SearchIteration = {
  id: string;
  searchId: string;
  name: string;
  lists: Lists,
  keywordGroups: KeywordGroup[];
  companySpecs: CompanySpecs;
  user: User;
  createdAt: string;
  status: string;
  debug: boolean;
  state: State;
  stages: Stage[];
};


export type KeywordGroupType = {
  type: string;
  cap: number;
  weight: number;
  placeholder: string
}

export const PlaceholderMustHave = `sample required keywords for pool services companies:

pool maintenance
pool cleaning
pool repair
pool filtration
swimming pool services
pool water testing
pool equipment repair
pool liner replacement
pool leak detection`

export const PlaceholderNiceToHave = `sample nice to have keywords for pool services companies:

hot tub repair
pool mosaics
pool construction
pool landscaping
pool water features`

export const PlaceholderExclude = `sample keywords to exclude for pool services companies

pool distributor
pool store
pools for sale`


export const TooltipMustHave = `At least one of the keywords has to appear within company's website text.

Enter keywords or phrases one per line. Use "" for exact matches.`

export const TooltipNiceToHave = `Companies that contain keywords from this group will be ranked higher in search results.

Enter keywords or phrases one per line. Use "" for exact matches.`

export const TooltipExclude = `A single occurrence of any of the keywords from this group will decrease the keyword search score. Multiple occurrences will exclude a company from search results.

Enter keywords or phrases one per line. Use "" for exact matches.`


export const KeywordGroupTypes = {
  Require: {
    id: "Require",
    type: "Require",
    weight: 5,
    cap: 75,
  },
  PositiveMatch: {
    id: "PositiveMatch",
    type: "Positive Match",
    weight: 1,
    cap: 25,
  },
  NegativeMatch: {
    id: "NegativeMatch",
    type: "Negative Match",
    weight: -5,
    cap: -75,
  },
  Exclude: {
    id: "Exclude",
    type: "Exclude",
    weight: -10,
    cap: -30,
  },
};

export type KeywordGroup = {
  id: string;
  type: string;
  name: string;
  weight: number;
  keywords: Keyword[];
  cap: number | null;
};

export type Keyword = {
  keyword: string;
};

export type State = {
  status: string;
  complete: boolean;
}

export type CompanySpecs = {
  geolocation: Geolocation,
  industry: Industry,
  employeeRange: EmployeeRange,
  nameOrDomain: NameOrDomain
}

export type Option = {
  id: string,
  label: string,
}

export type OptionMetadata = {
  size: number,
  loaded: boolean,
}

export type Lists = {
  type: string,
  value: Option[],
}

export type NameOrDomain = {
  type: string,
  value: string[],
}

export type EmployeeRange = {
  min: number,
  max: number,
  source: string,
}

export type Geolocation = {
  city: {
    type: string,
    value: string[]
  },
  state: {
    type: string,
    value: string[]
  },
  country: {
    type: string,
    value: string[]
  }
}

export type Industry = {
  sic2: {
    type: string,
    value: string[],
    includeSimilar: boolean,
  },
}

export type SearchIterationResultScore = {
  searchScore: any
}

export type SearchIterationResult = Companies & SearchIterationResultScore


export type Analysis = {
  id: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  user: User,
  parameters: AnalysisParameters,
  complete: boolean,
  status: string,
  results: AnalysisResults,
}

export type AnalysisResults = {
  industries: Map<string, number>,
  companySizes: Map<string, number>,
  states: Map<string, number>,
  keywords: Map<string, number>,
  phrases: Map<string, number>,
}

export type AnalysisParameters = {
  description: string,
  watchlist: {
    id: string,
    label: string,
  },
}

export const NewListOption: Option = {
  id: "",
  label: "Create New List",
}

export const NewScreenerOption: Option = {
  id: "",
  label: "Create New Screener",
}

export const NewSearchOption: Option = {
  id: "create_new_search",
  label: "Create New Search",
}

export const NewListFromAnalysisOption: Option = {
  id: "create_new_list",
  label: "Create New List",
}

export type Screener = {
  id: string,
  name: string,
  type: string,
  complete: boolean,
  createdBy: {
    id: string,
    email: string,
    organization: string,
  },
  createdAt: string,
  entries?: ScreenerEntry[]
  iteration?: SearchIteration,
  totalNumResults: number,
}

export type ScreenerEntry = {
  id: string,
  company: Companies,
  addedBy: User,
  addedAt: string,
}

export const KeywordGroups = [
  {
    id: "must",
    name: "Must have Keywords",
    type: KeywordGroupTypes.Require.id,
    weight: KeywordGroupTypes.Require.weight,
    cap: KeywordGroupTypes.Require.cap,
    keywords: [],
  },
  {
    id: "nice_to_have",
    name: "Nice to have Keywords",
    type: KeywordGroupTypes.PositiveMatch.id,
    weight: KeywordGroupTypes.PositiveMatch.weight,
    cap: KeywordGroupTypes.PositiveMatch.cap,
    keywords: [],
  },
  {
    id: "exclude",
    name: "Keywords to Exclude",
    type: KeywordGroupTypes.Exclude.id,
    weight: KeywordGroupTypes.Exclude.weight,
    cap: KeywordGroupTypes.Exclude.cap,
    keywords: [],
  },
]

export type Filters = {
  signalType: string[],
  dateRange: {
    key: string,
    start: Dayjs | null,
    end: Dayjs | null,
    apply: boolean,
  }
  list: {
    id: string,
    name: string,
  }
}
