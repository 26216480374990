import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SessionContext } from "../providers/SessionProvider";

export const Protected = ({ children }: { children: any }) => {
  const session = useContext(SessionContext);

  const isPaywall = (
    session.user?.organization.billingInformation.status === "Paywall"
  )
  const needsRedirect = window.location.pathname === "/accounts"

  return session.exists ? (
    (isPaywall && !needsRedirect) ? <Navigate to={"/accounts"} replace /> : children
  ) : <Navigate to={"/login"} replace />;
}

export const Admin = ({ children }: { children: any }) => {
  const session = useContext(SessionContext);

  return (
    <Protected>
      {/* TODO: show toast and bounce to previous page */}
      {session.user?.isAdmin ? children : <Navigate to={"/searches"} replace />}
    </Protected>
  )
};
