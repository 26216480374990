import {createContext, ReactNode, useContext, useEffect} from "react";

import { useImmer } from "use-immer";
import ModalComponents from "../components/Modal";
import {
  Box, Button, Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Table, TableBody,
  TableContainer,
  TableRow,
  Tooltip
} from "@mui/material";
import * as React from "react";
import axios from "axios";
import {camelize} from "../utils";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {Companies} from "../types/companies";
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import {AddListModalContext} from "../components/AddListModal";
import {AddIntentModalContext} from "../components/AddIntentModal";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import SearchBar from "../components/SearchBar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CompanyRow from "../components/tables/CompanyRow";
import {TableCell, TableHead} from "../components/tables/common";
import {RemoveIntentModalContext} from "../components/RemoveIntentModal";
import {useMediaQueryContext} from "./MediaQueryProvider";
import {AddToScreenerModalContext} from "../components/AddToScreenerModal";

const PerPage = 10

export type SearchParameters = {
  keywords: string
}

export type SearchContextValue = {
  open: boolean
  onOpen: (sp?: SearchParameters) => void,
  onSearch: (sp?: SearchParameters) => Promise<void>,
  onClose: () => void,
  onClear: () => void,
  onFocus: () => void,
  setOnFocus: (onFocus: () => void) => void,
  setOnIntentUpdate: (onIntentUpdate: () => Promise<void>) => void,
  setOnClose: (onClose: () => void) => void,
};

export const SearchContext = createContext<SearchContextValue>({
  open: false,
  onOpen: (sp?: SearchParameters) => {},
  onSearch: async (sp?: SearchParameters) => {},
  onClose: () => {},
  onClear: () => {},
  onFocus: () => {},
  setOnFocus: (onFocus: () => void) => {},
  setOnIntentUpdate: (onIntentUpdate: () => Promise<void>) => {},
  setOnClose: (onClose: () => void) => {},
});

type SearchResultEntry = {
  company: Companies,
  hasIntent: boolean,
  isTracked: boolean,
}

const SearchProvider = ({ children }: { children: ReactNode }) => {
  const addListModal = useContext(AddListModalContext);
  const addToScreenerModal = useContext(AddToScreenerModalContext)
  const removeIntentModal = useContext(RemoveIntentModalContext)
  const { isLowRes, isMediumRes } = useMediaQueryContext()

  const clearedState = {
    keywords: "",
    results: [],
    totalResults: 0,
    hasMore: false,
    page: 1,
    firstSearch: true,
    bulkSelected: new Set<string>(),
  }

  const defaultState = {
    open: false,
    inProgress: false,
    onClose: () => {},
    onFocus: () => {},
    ...clearedState,
  }

  const [state, setState] = useImmer<{
    open: boolean,
    keywords: string,
    results: SearchResultEntry[],
    hasMore: boolean,
    inProgress: boolean,
    firstSearch: boolean,
    bulkSelected: Set<string>,
    page: number,
    onIntentUpdate?: () => Promise<void>
    onClose: () => void,
    onFocus: () => void,
  }>(defaultState);

  const ResultItem = ({
    entry,
    selected,
    onSelect,
  }: {
    entry: SearchResultEntry,
    selected: boolean,
    onSelect: (key: string) => void,
  }) => {
    return (
      <CompanyRow
        key={`entry`}
        company={entry.company}
      >
        <TableCell
          padding={"checkbox"}
          sx={{
            padding: "0 16px 0 16px"
          }}
        >
          <Checkbox
            size={ isMediumRes || isLowRes ? "small" : undefined}
            checked={selected}
            onChange={() => onSelect(entry.company.domainName)}
          />
        </TableCell>
        <TableCell>
          {entry.company.name}
        </TableCell>
        <TableCell>
          <a href={`https://${entry.company.domainName}`} target="_blank" >{entry.company.domainName}</a>
        </TableCell>
        <TableCell>
          {entry.hasIntent ? "Y": "N"}
        </TableCell>
        <TableCell
          padding={"normal"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"right"}
          >
            <Tooltip title="Add to List" arrow>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  addListModal.show([entry.company.domainName])
                }}
              >
                <PlaylistAddOutlinedIcon/>
              </IconButton>
            </Tooltip>
            {
              entry.hasIntent ? (
                <Tooltip title="Add to screener" arrow>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      addToScreenerModal.show(
                        [entry.company.domainName],
                        () => {
                          setIntentTracked(entry.company.domainName, true)
                          if (state.onIntentUpdate) {
                            state.onIntentUpdate()
                          }
                        },
                        false,
                      )
                    }}
                  >
                    <BookmarkAddIcon color="success" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="No intent available" arrow>
                  <IconButton >
                    <BookmarkBorderIcon color="disabled" />
                  </IconButton>
                </Tooltip>
              )
            }
          </Box>
        </TableCell>
      </CompanyRow>
    )
  }

  const fetchSearchResults = async (
    showMore: boolean = false,
  ) => {
    let start =  0
    let end = PerPage

    if (showMore) {
      start = state.page * PerPage
      end = (state.page + 1) * PerPage

      setState((state) => {
        state.page += 1
      })
    }

    await axios({
      method: "GET",
      url: `/api/v1/companies/search`,
      params: {
        keywords: state.keywords,
        start: start,
        end: end,
      },
      withCredentials: true,
    }).then((response) => {
      setState((state) => {
        const camelizedPayload = camelize(response.data)
        const results = camelizedPayload["results"] as SearchResultEntry[]
        if (showMore) {
          state.results = [
            ...state.results,
            ...results,
          ]
        } else {
          state.results = results;
        }
        state.hasMore = camelizedPayload["metadata"]["hasMore"] as boolean
        state.firstSearch = false
      });
    }).catch((error) => {
      console.log(error);
    })
  }

  const runSearch = async (
    showMore: boolean = false,
  ) => {
    if (state.keywords && !state.inProgress) {
      setState((state) => {
        state.inProgress = true
      })

      return fetchSearchResults(showMore).then(() => {
        setState((state) => {
          state.inProgress = false
        })
      })
    }
  }

  useEffect(() => {
    if (state.open) {
      if (state.keywords === "") {
        context.onClear()
        context.onClose()
      } else {
        runSearch()
      }
    } else if (state.keywords !== "") {
      // for onSearch uses
      runSearch().then(() => {
        setState((state) => {
          state.open = true
        })
      })
    }
  }, [state.keywords])

  const context = {
    open: state.open,
    onOpen: (sp?: SearchParameters) => {
      setState((state) => {
        state.open = true
        state.keywords = sp?.keywords || ""
      })
    },
    onClose: () => {
      setState(defaultState)
    },
    onClear: () => {
      setState({
        ...clearedState,
        open: state.open,
        inProgress: state.inProgress,
        bulkSelected: new Set<string>(),
        onClose: state.onClose,
        onFocus: state.onFocus,
      })
    },
    setOnIntentUpdate: (onIntentUpdate: () => Promise<void>) => {
      setState((state) => {
        state.onIntentUpdate = onIntentUpdate
      })
    },
    setOnClose: (onClose: () => void) => {
      setState((state) => {
        state.onClose = onClose
      })
    },
    onSearch: async (searchParameters?: SearchParameters) => {
      setState((state) => {
        if (searchParameters?.keywords) {
          state.keywords = searchParameters?.keywords
        } else {
          state.keywords = ""
          state.open = true
        }
      })
    },
    setOnFocus: (onFocus: () => void) => {
      setState((state) => {
        state.onFocus = onFocus
      })
    },
    onFocus: () => {
      state.onFocus()
    },
  }

  const noneSelected = state.bulkSelected.size === 0
  const someSelected = state.bulkSelected.size > 0
  const allSelected = state.bulkSelected.size === state.results.length && state.results.length > 0

  const someTracked = (
    state.results
      .filter((se) => se.isTracked && se.hasIntent)
      .filter((se) => state.bulkSelected.has(se.company.domainName))
      .length > 0
  )
  const someUntracked = (
    state.results
      .filter((se) => !se.isTracked && se.hasIntent)
      .filter((se) => state.bulkSelected.has(se.company.domainName))
      .length > 0
  )

  const onSelect = (key: string) => {
    setState((state) => {
      if (state.bulkSelected.has(key)) {
        state.bulkSelected.delete(key)
      } else {
        state.bulkSelected.add(key)
      }
    })
  }

  const onSelectAll = () => {
    setState((state) => {
      state.bulkSelected = new Set<string>(state.results.map((e) => e.company.domainName))
    })
  }

  const onUnselectAll = () => {
    setState((state) => {
      state.bulkSelected = new Set<string>()
    })
  }

  const indexOfDomain = (results: SearchResultEntry[], domain: string) => {
    return state.results.map((se) => se.company.domainName === domain).indexOf(true)
  }

  const domainIsTracked = (results: SearchResultEntry[], domain: string) => {
    const idx = indexOfDomain(results, domain)
    return results[idx].isTracked
  }

  const setIntentTracked = (domain: string, value: boolean) => {
    setState((state) => {
      const idx = indexOfDomain(state.results, domain)
      state.results[idx].isTracked = value
    })
  }

  const endAdornment = (
    state.inProgress ? (
      <InputAdornment position="end">
        <CircularProgress color="inherit" size={16} />
      </InputAdornment>
    ) : state.keywords.length > 0 ? (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            context.onClear()
            setState((state) => {
              state.open = false
            })
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </InputAdornment>
    ) : null
  )

  const hasSearchRun = !state.firstSearch

  const footer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {
        state.hasMore ? (
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => runSearch(true)}
          >
            Show More
          </Button>
        ) : <Box></Box>
      }
    </Box>
  )

  const checkbox = (
    <Box
      style={{
        padding: "0 0 1rem 16px",
        height: "100%",
      }}
      display={"flex"}
      flexDirection={"row"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
      >
        <Tooltip title="Add to List" arrow>
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              addListModal.show(Array.from(state.bulkSelected))
            }}
            disabled={!someSelected}
          >
            <PlaylistAddOutlinedIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Add all to screener" arrow>
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              addToScreenerModal.show(
                Array.from(state.bulkSelected).filter((domain) => !domainIsTracked(state.results, domain)),
                () => {
                  state.bulkSelected.forEach((domain) => setIntentTracked(domain, true))
                  if (state.onIntentUpdate) {
                    state.onIntentUpdate()
                  }
                },
                false,
              )
            }}
            disabled={!someUntracked}
          >
            <BookmarkAddIcon color={someUntracked ? "success" : "disabled"} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )

  const resultsModalBody = (
    state.results.length > 0 ? (
      <TableContainer
        sx={{
          overflowY: "scroll",
          marginTop: "-0.1rem",
        }}
      >
        <Table
          stickyHeader
          size="small"
          sx={{
            height: "max-content",
          }}
        >
          <TableHead
            sx={{
              paddingTop: "5rem",
            }}
          >
            <TableRow>
              <TableCell
                padding={"checkbox"}
                sx={{
                  padding: "0 16px 0 16px"
                }}
              >
                <Tooltip
                  title={`Select Displayed (${state.results.length})`}
                  arrow
                >
                  <Checkbox
                    size={ isMediumRes || isLowRes ? "small" : undefined}
                    checked={allSelected}
                    indeterminate={someSelected && !allSelected}
                    onChange={() => {
                      if (someSelected) {
                        onUnselectAll()
                      } else if (noneSelected) {
                        onSelectAll()
                      }
                    }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>
                Company Name
              </TableCell>
              <TableCell>
                Domain Name
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1rem"
                  }}
                >
                  Financial Intent
                  <Tooltip
                    title={
                      <h3>
                        An indication whether <a href="https://fintent.ai/financial-intent/" target="_blank" >financial intent</a> signals are produced on the company.
                      </h3>
                    }
                    arrow
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.results.map((entry) => (
                <ResultItem
                  entry={entry}
                  selected={state.bulkSelected.has(entry.company.domainName)}
                  onSelect={onSelect}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        No matches found. Please, try another search.
      </Box>
    )
  )

  const modalBody = (
    state.firstSearch ? (
      <></>
    ) : (
      resultsModalBody
    )
  )

  return (
    <SearchContext.Provider value={context}>
      {children}
      <ModalComponents.ModalContainer
        open={context.open}
        onClose={() => {
          state.onClose()
          context.onClose()
        }}
        sx={{
          height: state.firstSearch ? "inherit" : "75%",
          maxWidth: "60%",
          display: "flex",
        }}
      >
        <ModalComponents.ModalPage
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          title={"Search"}
          body={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                paddingTop: "1rem",
              }}
            >
              <Box
                id="header"
                display="flex"
                flexDirection="column"
                sx={{
                  gap: "1rem"
                }}
              >
                <SearchBar
                  value={state.keywords}
                  placeholder="Search for company by domain"
                  onSearch={(keywords) => {
                    setState((state) => {
                      state.keywords = keywords
                    })

                  }}
                  onClear={() => {
                    setState((state) => {
                      state.keywords = ""
                    })
                  }}
                  inputProps={{
                    endAdornment: endAdornment,
                  }}
                />
                <Box>
                  {
                    (state.firstSearch || state.results.length === 0) ? null : checkbox
                  }
                </Box>
              </Box>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}
              >
                {
                  modalBody
                }
              </Box>
              {
                hasSearchRun ? (
                  <Box
                    sx={{
                      padding: "1rem 0",
                    }}
                  >
                    {
                      footer
                    }
                  </Box>
                ) : null
              }
            </Box>
          }
        />
      </ModalComponents.ModalContainer>
    </SearchContext.Provider>
  );
}

export default SearchProvider
