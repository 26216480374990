import React, {createContext, ReactNode, useContext} from 'react';
import { useMediaQuery } from '@mui/material';

const MediaQueryContext = createContext({
  isLowRes: false,
  isMediumRes: false,
  isHighRes: false,
});

const MediaQueryProvider = ({
  children
}: {
  children: ReactNode,
}) => {
  const isLowRes = useMediaQuery('(max-width:1147px)');
  const isMediumRes = useMediaQuery('(min-width:1148px) and (max-width:1920px)');
  const isHighRes = useMediaQuery('(min-width:1921px) and (max-width:2560px)');

  return (
    <MediaQueryContext.Provider value={{ isLowRes, isMediumRes, isHighRes }}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = () => {
  return useContext(MediaQueryContext);
};

export default MediaQueryProvider