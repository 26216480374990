import {createContext, ReactNode, useContext, useEffect} from "react";

import { useImmer } from "use-immer";
import {User} from "../types/search";
import axios from "axios";
import {camelize} from "../utils";
import {PageHeaderContext} from "./PageHeaderProvider";

export type Session = {
  user?: User
}

export type SessionContextValue = Session & {
  exists: boolean
  login: (user: User) => void
  refresh: () => Promise<void>,
  logout: () => void,
  setShownWelcomeAt: () => void
};

export const SessionContext = createContext<SessionContextValue>({
  exists: false,
  login: async () => {},
  logout: async () => {},
  refresh: async () => {},
  setShownWelcomeAt: () => {},
  user: undefined
});

const SESSION_KEY = "fintent-session";

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const pageHeader = useContext(PageHeaderContext);

  const getSession = () => {
    try {
      const session_value = localStorage.getItem(SESSION_KEY);
      if (session_value) {
        return JSON.parse(session_value) as Session;
      } else {
        return undefined;
      }
    } catch {
      console.error("unable to parse session from local storage")
      return undefined;
    }
  };

  const [state, setState] = useImmer<{
    exists: boolean;
    session?: Session;
  }>({
    exists: getSession() != null,
    session: getSession(),
  });

  const login = (user: User) => {
    const session = {
      user: user,
      version: 1,
    };
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
    setState({
      exists: true,
      session,
    });
  };

  const setShownWelcomeAt = () => {
    if (state.session && state.session?.user) {
      setState((state) => {
        // @ts-ignore
        state.session.user.shownWelcomeAt = (new Date()).toString();
      })
      localStorage.setItem(SESSION_KEY, JSON.stringify(state.session));
    }
  }

  const logout = () => {
    pageHeader.setPageHeader("", "", undefined)
    localStorage.removeItem(SESSION_KEY);
    setState((state) => {
      state.exists = false;
    });
  };

  const refresh = async () => {
    axios({
      method: "GET",
      url: `/api/v1/session/refresh`,
    })
      .then(function (response) {
        login(camelize(response.data) as User)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    window.addEventListener("storage", (e: StorageEvent) => {
      if (e.key === SESSION_KEY) {
        const session = getSession();
        setState({
          exists: session != null,
          session,
        });
      }
    });
  });

  useEffect(() => {
    if (state.exists) {
      refresh()
    }
  }, [state.exists])

  const context = {
    exists: state.exists,
    login,
    logout,
    refresh,
    setShownWelcomeAt,
    ...state.session,
  };

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
