import Box from "@mui/material/Box";
import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import * as React from "react";

const TruncatedList = ({
  domains,
  maxRows,
  icon,
}: {
  domains: string[],
  maxRows: number,
  icon: JSX.Element
}) => {
  return (
    <Box>
      <List>
        {
          domains.slice(0, maxRows).map((domain) => (
            <ListItem disablePadding>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              <ListItemText primary={domain} />
            </ListItem>
          ))
        }
      </List>
      {
        domains.length > maxRows ? (
          <Box>
            <Typography variant="subtitle2">
              +{domains.slice(maxRows).length} additional domains
            </Typography>
          </Box>
        ) : null
      }
    </Box>
  )
}

export default TruncatedList
