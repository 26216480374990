const initialState = {
  message: "",
  severity: "",
}

const alertsReducer = (state = initialState, action: any) => {
  return {
    message: action.message,
    severity: action.severity,
  }
}

export default alertsReducer