import ModalComponents from "../../components/Modal";
import {Button, IconButton, TextField, Tooltip} from "@mui/material";
import * as React from "react";
import {Search} from "../../types/search";
import {useImmer} from "use-immer";
import {createContext, ReactNode, useContext, useEffect} from "react";
import axios from "axios";
import {ToastContext} from "../../providers/ToastProvider";


type EditSearchModalContextValue = {
  openUpdateSearch: (search: Search, callbackFn?: (newName: string) => void) => void,
}

export const EditSearchModalContext = createContext<EditSearchModalContextValue>({
  openUpdateSearch: (search: Search, callbackFn?: (newName: string) => void) => {},
});

const EditSearchModal = ({
  open,
  onClose,
  search,
  callbackFn,
}: {
  open: boolean;
  onClose: () => void,
  search?: Search;
  callbackFn: (newName: string) => void,
}) => {
  const {setSuccess, setError} = useContext(ToastContext)

  useEffect(() => {
    if (search) {
      setState((state) => {
        state.updatedSearchName = search.name
      })
    }
  }, [search?.name])

  const defaultState = {
    updatedSearchName: "",
    requestPending: false,
  }

  const [state, setState] = useImmer<{
    updatedSearchName: string
    requestPending: boolean
  }>(defaultState);

  const onSubmit = (callbackFn?: (name: string) => void) => {
    setState((state) => {
      state.requestPending = true
    });
    axios({
      method: "PUT",
      url: `/api/v1/searches/${search?.id}`,
      withCredentials: true,
      data: {
        name: state.updatedSearchName,
      }
    }).then((response) => {
      if (callbackFn) {
        callbackFn(state.updatedSearchName)
      }
      setSuccess("Search updated successfully")
    }).catch((error) => {
      setError("Error updating search")
    }).finally(() => {
      onClose()
    })
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={() => {
        onClose()
        setState(defaultState)
      }}
    >
      <ModalComponents.ModalPage
        title={`Update "${search?.name}"`}
        body={
          <TextField
            autoFocus
            value={state.updatedSearchName}
            label="Name"
            sx={{
              marginTop: "1rem",
            }}
            onChange={(e) => {
              setState((state) => {
                state.updatedSearchName = e.target.value
              })
            }}
            fullWidth
          />
        }
        buttons={
          <>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={state.updatedSearchName === search?.name || state.updatedSearchName.length === 0}
              onClick={() => {
                onSubmit(callbackFn)
                onClose()
              }}
            >
              Update
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  )
}


const EditSearchModalProvider = ({
  children,
}: {
  children: ReactNode,
}) => {
  const defaultState = {
    open: false,
    callbackFn: () => {},
    requestPending: false,
    search: undefined,
    updatedSearchName: ""
  }

  const [state, setState] = useImmer<{
    open: boolean,
    callbackFn: (newName: string) => void
    requestPending: boolean,
    search?: Search,
    updatedSearchName: string
  }>(defaultState);

  const onClose = () => {
    setState(defaultState)
  }

  const noneCallbackFn = (newName: string) => {}

  const context = {
    openUpdateSearch: (search: Search, callbackFn?: (newName: string) => void) => {
      setState((state) => {
        state.search = search
        state.open = true
        state.callbackFn = callbackFn || noneCallbackFn
      })
    }
  }

  return (
    <EditSearchModalContext.Provider value={context}>
      {children}
      <EditSearchModal
        open={state.open}
        search={state.search}
        onClose={onClose}
        callbackFn={state.callbackFn}
      />
    </EditSearchModalContext.Provider>
  )
}


export default EditSearchModalProvider