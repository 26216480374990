import ModalComponents from "../Modal";
import {Box, Button, CircularProgress, TextField} from "@mui/material";
import * as React from "react";
import {useImmer} from "use-immer";
import {createContext, ReactNode, useContext, useEffect} from "react";
import axios from "axios";
import {camelize} from "../../utils";
import {User} from "../../types/search";
import store from "../../store";
import {ToastContext} from "../../providers/ToastProvider";


type SuggestKeywordModalContextValue = {
  openModal: () => void,
  setCallback: (fn: (mustHave: string[], niceToHave: string[], exclude: string[]) => void) => void,
  loaded: boolean,
}

export const SuggestKeywordModalContext = createContext<SuggestKeywordModalContextValue>({
  openModal: () => {},
  setCallback: (fn: (mustHave: string[], niceToHave: string[], exclude: string[]) => void) => {},
  loaded: false,
});

const KeywordSuggestionModal = ({
  open,
  onClose,
  setPositiveKeywords,
  setNegativeKeywords,
  onSubmit,
  inProgress,
}: {
  open: boolean
  onClose: () => void,
  setPositiveKeywords: (keywords: string) => void,
  setNegativeKeywords: (keywords: string) => void,
  onSubmit: () => void,
  inProgress: boolean
}) => {

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={onClose}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit()
          // onClose()
        }}
      >
        <ModalComponents.ModalPage
          title={"Suggest Keywords"}
          body={
            <>
              <h3>Describe what types of companies you're looking for</h3>
              <TextField
                placeholder="ex: ecommerce beauty company, mid-market plumbing, small HVAC businesses"
                minRows={3}
                multiline
                fullWidth
                onChange={(e) => {
                  setPositiveKeywords(e.target.value)
                }}
              />
              <h3>Any company types you'd like to exclude from the search?</h3>
              <TextField
                placeholder="ex: education, non-profit, etc"
                minRows={3}
                multiline
                fullWidth
                onChange={(e) => {
                  setNegativeKeywords(e.target.value)
                }}
              />
            </>
          }
          buttons={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="stretch"
              width="100%"
              gap="1rem"
            >
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={onClose}
                sx={{
                  width: "50%",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="outlined"
                sx={{
                  width: "50%",
                }}
                disabled={inProgress}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="0.5rem"
                >
                  Suggest Keywords
                  {
                    inProgress ? <CircularProgress color="inherit" size={16} /> : null
                  }
                </Box>
              </Button>
            </Box>
          }
        />
      </form>
    </ModalComponents.ModalContainer>
  )
}

const KeywordSuggestionModalProvider = ({
  children,
}: {
  children: ReactNode,
}) => {
  const {setError} = useContext(ToastContext)

  const [state, setState] = useImmer<{
    loaded: boolean
    open: boolean,
    positiveKeywords: string,
    negativeKeywords: string,
    callbackFn: (mustHave: string[], niceToHave: string[], exclude: string[]) => void
    inProgress: boolean,
  }>({
    loaded: false,
    open: false,
    positiveKeywords: "",
    negativeKeywords: "",
    callbackFn: (mustHave: string[], niceToHave: string[], exclude: string[]) => {},
    inProgress: false,
  });

  const initialize = () => {
    setState((state) => {
      state.loaded = true
    })
  }

  useEffect(() => {
    if (!state.loaded) {
      initialize()
    }
  }, [])

  const onSubmit = () => {
    setState((state) => {
      state.inProgress = true
    })
    return axios({
      method: "POST",
      url: `/api/v1/searches/iterations/keywords/suggest`,
      data: {
        positive_keywords: state.positiveKeywords,
        negative_keywords: state.negativeKeywords,
      },
      withCredentials: true,
    }).then((response) => {
      setState((state) => {
        const body = camelize(response.data)
        const mustHave = body["mustHave"] as string[]
        const niceToHave = body["niceToHave"] as string[]
        const exclude = body["exclude"] as string[]
        state.callbackFn(mustHave, niceToHave, exclude)
      });
      onClose()
    }).catch(function (error) {
      console.log(error);
      setError("Unable to generate keywords, please try again")
    }).finally(() => {
      setState((state) => {
        state.inProgress = false
      })
    });
  }

  const onClose = () => {
    setState((state) =>{
      state.open =  false
      state.positiveKeywords = ""
      state.negativeKeywords = ""
      state.inProgress = false
    })
  }

  const context = {
    openModal: () => {
      setState((state) => {
        state.open = true
      })
    },
    setCallback: (callbackFn: (mustHave: string[], niceToHave: string[], exclude: string[]) => void) => {
      setState((state) => {
        state.callbackFn = callbackFn
      })
    },
    loaded: state.loaded,
  }

  return (
    <SuggestKeywordModalContext.Provider value={context}>
      {children}
      <KeywordSuggestionModal
        open={state.open}
        onClose={onClose}
        setPositiveKeywords={(keywords: string) => {
          setState((state) => {
            state.positiveKeywords = keywords
          })
        }}
        setNegativeKeywords={(keywords: string) => {
          setState((state) => {
            state.negativeKeywords = keywords
          })
        }}
        onSubmit={onSubmit}
        inProgress={state.inProgress}
      />
    </SuggestKeywordModalContext.Provider>
  )
}


export default KeywordSuggestionModalProvider