import {useImmer} from "use-immer";
import {NewListOption, Option} from "../../types/search";
import {Watchlist} from "../../types/watchlist";
import axios from "axios";
import {camelize} from "../../utils";
import {createContext, useEffect} from "react";
import ModalComponents from "../Modal";
import * as React from "react";
import {Autocomplete, Box, Button, TextField, Typography} from "@mui/material";
import TruncatedList from "../../pages/watchlists/components/TruncatedList";
import {ArrowRight} from "@mui/icons-material";
import {cleanDomains} from "../../pages/watchlists/components/utils";
import {AlertColor} from "@mui/material/Alert";
import Toast from "../Toast";


const onSubmit = (
  listId: string,
  newListName: string,
  domainNames: string[],
  phase: number,
  setNextPhase: (phase: number, redirectListId: string) => void,
) => {
  if (newListName) {
    axios({
      method: "POST",
      url: `/api/v1/watchlists/new`,
      data: {
        name: newListName,
        domains: cleanDomains(domainNames),
        unmatched: [],
      },
      withCredentials: true,
    }).then((response) => {
      const redirectListId = camelize(response.data).id
      setNextPhase(phase + 1, redirectListId)
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      // TODO: figure out locking
    })
  } else {
    axios({
      // TODO: add source
      method: "POST",
      url: `/api/v1/watchlists/${listId}/entries`,
      data: {
        domains: cleanDomains(domainNames),
        unmatched: [],
      },
      withCredentials: true,
    }).then((response) => {
      setNextPhase(phase + 1, listId)
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      // TODO: figure out locking
    })
  }
}

const Success = ({
  domainNames,
  newListName,
  selectedList,
  redirectListId,
  onClose,
}: {
  domainNames: string[],
  newListName: string,
  selectedList: Option,
  redirectListId: string,
  onClose: () => void,
}) => {
  const title = newListName.length > 0 ? `new list '${newListName}'` : `list '${selectedList.label}'`
  const content = `Added ${domainNames.length} domains to ${title}`
  return (
    <ModalComponents.ModalPage
      title={"Success"}
      body={
        <ModalComponents.ModalBodyText
          content={content}
        />
      }
      buttons={
        <>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            OK
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            href={`/watchlists/${redirectListId}`}
          >
            Go to List
          </Button>
        </>
      }
    />
  )
}

const Overview = ({
  lists,
  selectedList,
  domainNames,
  setSelectedList,
  newListName,
  setNewListName,
  phase,
  setNextPhase,
  onClose,
}: {
  lists: Watchlist[],
  selectedList: Option,
  domainNames: string[],
  setSelectedList: (list: Option) => void
  newListName: string,
  setNewListName: (name: string) => void
  phase: number,
  setNextPhase: (phase: number, redirectListId: string) => void,
  onClose: () => void
}) => {
  return (
    <ModalComponents.ModalPage
      title={"Add to List"}
      body={
        <>
          <Box
            sx={{
              padding: "1rem 0"
            }}
          >
            <Autocomplete
              size="small"
              options={[
                NewListOption,
                ...([...lists].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                  .map((l) => ({
                    id: l.id,
                    label: l.name,
                  })))
              ]}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option: Option, value: Option) => {
                return option.id === value.id
              }}
              disableClearable
              value={selectedList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
              onChange={(e, value: Option) => {
                setSelectedList(value)
              }}
            />
            {
              selectedList.label === NewListOption.label ? (
                <Box
                  sx={{
                    paddingTop: "0.5rem"
                  }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    label="New List Name"
                    required
                    fullWidth
                    onChange={(e) => {
                      setNewListName(e.target.value)
                    }}
                  />
                </Box>
              ): null
            }
          </Box>
          <Box>
            <Typography variant="h6" component="h4">
              Domains
            </Typography>
            <TruncatedList
              domains={domainNames}
              maxRows={8}
              icon={<ArrowRight color="success" />}
            />
          </Box>
        </>
      }
      buttons={
        <>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            onClick={() => {
              onSubmit(
                selectedList.id,
                newListName,
                domainNames,
                phase,
                setNextPhase,
              )
            }}
          >
            Submit
          </Button>
        </>
      }
    />
  )
}

const AddListModal = ({
  open,
  onClose,
  domainNames,
}: {
  open: boolean,
  onClose: () => void,
  domainNames: string[],
}) => {
  const [state, setState] = useImmer<{
    loaded: boolean,
    phase: number,
    newListName: string,
    selectedList: Option,
    lists: Watchlist[],
    redirectListId: string,
  }>({
    loaded: false,
    phase: 0,
    newListName: "",
    selectedList: {
      id: "",
      label: "",
    },
    lists: [],
    redirectListId: "",
  })

  const initialize = () => {
    axios({
      method: "GET",
      url: `/api/v1/watchlists`,
      withCredentials: true,
    }).then((response) => {
      setState((state) => {
        state.lists = camelize(response.data).watchlists as Watchlist[]
        state.loaded = true
      })
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (!state.loaded) {
      initialize();
    }
  });

  const resetAndClose = () => {
    setState((state) => {
      state.phase = 0
    })
    onClose()
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={onClose}
    >
      {
        state.phase === 0 ? (
          <Overview
            lists={state.lists}
            selectedList={state.selectedList}
            domainNames={domainNames}
            setSelectedList={(value: Option) => {
              setState((state) => {
                state.selectedList = value
              })
            }}
            newListName={state.newListName}
            setNewListName={(name: string) => {
              setState((state) => {
                state.newListName = name
              })
            }}
            phase={state.phase}
            setNextPhase={(phase: number, redirectListId: string) => {
              setState((state) => {
                state.phase += 1
                state.redirectListId = redirectListId
              })
            }}
            onClose={resetAndClose}
          />
        ) : (
          <Success
            domainNames={domainNames}
            newListName={state.newListName}
            selectedList={state.selectedList}
            redirectListId={state.redirectListId}
            onClose={resetAndClose}
          />
        )
      }
    </ModalComponents.ModalContainer>
  )
}


export type AddListModalContextValue = {
  show: (domains: string[]) => void
};

export const AddListModalContext = createContext<AddListModalContextValue>({
  show: (domains: string[]) => {}
});


const AddListModalProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean,
    onClose: () => void,
    domains: string[],
  }>({
    open: false,
    onClose: () => {},
    domains: [],
  });

  const context = {
    show: (domains) => {
      setState((state) => {
        state.domains = domains
        state.open = true
      })
    }
  } as AddListModalContextValue

  return (
    <AddListModalContext.Provider value={context}>
      {children}
      <AddListModal
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false
            state.domains = []
          })
        }}
        domainNames={state.domains}
      />
    </AddListModalContext.Provider>
  );
}


export default AddListModalProvider