import {CSSProperties, ReactNode} from "react";
import {Box, Modal, Typography} from "@mui/material";
import * as React from "react";

import styles from "./index.module.scss"
import classNames from "classnames";
import {useMediaQueryContext} from "../../providers/MediaQueryProvider";


const ModalContainer = ({
  open,
  onClose,
  children,
  sx,
}: {
  open: boolean,
  onClose: () => void,
  children: ReactNode,
  sx?: CSSProperties
}) => {
  const { isLowRes, isMediumRes, isHighRes } = useMediaQueryContext()

  return (
    <Modal
      className={classNames(styles.modal, {
        [styles.low]: isLowRes,
        [styles.medium]: isMediumRes,
        [styles.high]: isHighRes,
      })}
      open={open}
      onClose={onClose}
      disableAutoFocus
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.default',
          minWidth: "50%",
          boxShadow: 24,
          p: 4,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Modal>
  )
}

const ModalBodyText = ({content}: {content: string | ReactNode}) => {
  return (
    <Typography sx={{
      paddingTop: "2rem",
    }}>
      {content}
    </Typography>
  )
}

const ModalPage = ({
  title,
  titleColor = null,
  centerTitle = false,
  body,
  buttons=null,
  sx,
}: {
  title: string,
  titleColor?: string | null,
  centerTitle?: boolean
  body: ReactNode,
  buttons?: ReactNode
  sx?: CSSProperties,
}) => {
  return (
    <Box
      sx={sx}
    >
      <Box
        sx={{
          alignContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 900,
            "--title-color": titleColor,
            textAlign: centerTitle ? "center": "inherit",
          }}
          className={styles.title}
        >
          {title}
        </Typography>

      </Box>
      <Box
        sx={{
          height: "100%",
        }}
      >
        {body}
      </Box>
      {
        buttons ? (
          <Box
            sx={{
              paddingTop: "1rem",
              justifyContent: "space-evenly",
              display: "flex",
            }}
          >
            {buttons}
          </Box>
        ) : null
      }
    </Box>
  )
}

export default {
  ModalContainer,
  ModalBodyText,
  ModalPage,
}