export const EmployeeRanges = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 250,
    label: "250",
  },
  {
    value: 500,
    label: "500",
  },
  {
    value: 1000,
    label: "1000",
  },
  {
    value: 5000,
    label: "5000",
  },
  {
    value: 10000,
    label: "10000",
  },
  {
    value: 99999,
    label: "10000+",
  },
]

export const MaxEmployeeRangeValue = EmployeeRanges.length - 1
export const MinEmployeeRangeValue = 0

export const DescaleMapping = Object.fromEntries(EmployeeRanges.map((obj, i) => [obj.value, i]))

export const LabelToValue = new Map<string, number>(EmployeeRanges.map((e) => [e.label, e.value]))