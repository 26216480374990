import {camelCase, isArray, isObject, transform} from "lodash";
import {AnalysisResults, EmployeeRange} from "../types/search";
import * as React from "react";
import {DescaleMapping, LabelToValue} from "../data/employeeranges";
import {Dayjs} from "dayjs";
import {AxiosResponse} from "axios";

export const camelize = (obj: any) =>
  transform(obj, (result: any, value: unknown, key: string, target: any) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    result[camelKey] = isObject(value) ? camelize(value) : value;
  });


export const formatDate = (str: string) => {
  var d = new Date(str),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const formatDateMonth = (str: string) => {
  var d = new Date(str),
    month = '' + (d.getMonth() + 1),
    day = '' + (d.getDate()),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;

  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const formatTimestamp = (date?: string) => {
  if (!date) {
    return ""
  }
  const d = new Date(date)

  const pad = (n: number, s=2) => (`${new Array(s).fill(0)}${n}`).slice(-s);

  const hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours()
  const ampm = d.getHours() > 12 ? "PM" : "AM"

  return `${pad(d.getFullYear(),4)}-${pad(d.getMonth()+1)}-${pad(d.getDate())} ${pad(hours)}:${pad(d.getMinutes())} ${ampm}`;
}

export const sortTimestamp = <T,>(fn: (e: T) => string) => {
  return (a: T, b: T) => new Date(fn(b)).getTime() - new Date(fn(a)).getTime()
}

export const cleanDomain = (domain: string) => {
  const regex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/ig;

  const m = regex.exec(domain)
  if (m === null) {
    return null
  }
  return m[1]
}


export const buildURLParameters = (domains: string[]) => {
  const params = new URLSearchParams({})
  domains.forEach((domain) => {
    params.append("domains", domain)
  })
  return params.toString()
}

export const isInt = (n: number) => {
  return Number(n) === n && n % 1 === 0;
}

export const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
}

const mergeMaps = (a: Map<string, number>, b: Map<string, number>): Map<string, number> => {
  return new Map([...Array.from(a.entries()), ...Array.from(b.entries())])
}

const trimMap = (m: Map<string, number>, start: number, end: number): Map<string, number> => {
  return new Map([...Array.from(m.entries())].sort((a, b) => b[1] - a[1]).slice(start, end))
}

export const buildAnalysisRequiredGroup = (results: AnalysisResults) => {
  return mergeMaps(trimMap(results.phrases, 0, 5), trimMap(results.keywords, 0, 3))
}

export const buildAnalysisPositiveMatchGroup = (results: AnalysisResults) => {
  return mergeMaps(trimMap(results.phrases, 5, 10), trimMap(results.keywords, 3, 10))
}

export const topN = (map: Map<string, number>, maxItems: number) => {
  return new Map(Array.from(map.entries())
    .sort(([aKey, aCount], [bKey, bCount]) => bCount - aCount)
    .slice(0, maxItems))
}

export const minMaxCompanySizeRanges = (companySizes: string[]): EmployeeRange => {
  const discreteSizes = companySizes.flatMap((e) => e.split(" - "))
  const existingLabelToValue = new Map(discreteSizes.filter((e) => LabelToValue.has(e)).map((e) => [e, LabelToValue.get(e) || 0]))
  const min = Array.from(existingLabelToValue.values()).reduce((aValue, bValue) => Math.min(aValue, bValue))
  const max = Array.from(existingLabelToValue.values()).reduce((aValue, bValue) => Math.max(aValue, bValue))

  return {
    min: DescaleMapping[min],
    max: DescaleMapping[max],
    source: "range",
  }
}

const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const isURL = (url: string): boolean => {
  return urlRegex.test(url)
}

export const conformDate = (date: Dayjs | null) => {
  return date?.format("YYYYMM") || ""
}

export const displayDate = (date: Dayjs | null) => {
  return date?.format("MMM YYYY") || ""
}

export const indexOf = (t: {id: string}, ts: {id: string}[]) => {
  return ts.map((_t) => t.id === _t.id).indexOf(true)
}

export const download = (response: AxiosResponse<any>) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const headerLine = response.headers['content-disposition'] || "";
  const startFileNameIndex = headerLine.indexOf('=') + 1
  const filename = headerLine.substring(startFileNameIndex);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
