import ModalComponents from "../../components/Modal";
import {Button, TextField} from "@mui/material";
import * as React from "react";
import {SearchIteration} from "../../types/search";
import {useImmer} from "use-immer";
import {createContext, ReactNode, useContext, useEffect} from "react";
import axios from "axios";
import {ToastContext} from "../../providers/ToastProvider";


type EditSearchIterationModalContextValue = {
  openUpdateSearchIteration: (search: SearchIteration, callbackFn?: (newName: string) => void) => void,
}

export const EditSearchIterationModalContext = createContext<EditSearchIterationModalContextValue>({
  openUpdateSearchIteration: (searchIteration: SearchIteration, callbackFn?: (newName: string) => void) => {},
});

const EditSearchIterationModal = ({
  open,
  onClose,
  searchIteration,
  callbackFn,
}: {
  open: boolean;
  onClose: () => void,
  searchIteration?: SearchIteration;
  callbackFn: (newName: string) => void,
}) => {
  const {setSuccess, setError} = useContext(ToastContext)

  useEffect(() => {
    if (searchIteration) {
      setState((state) => {
        state.updatedSearchIterationName = searchIteration.name
      })
    }
  }, [searchIteration?.name])

  const defaultState = {
    updatedSearchIterationName: "",
    requestPending: false,
  }

  const [state, setState] = useImmer<{
    updatedSearchIterationName: string
    requestPending: boolean
  }>(defaultState);

  const onSubmit = (callbackFn?: (name: string) => void) => {
    setState((state) => {
      state.requestPending = true
    });
    axios({
      method: "PUT",
      url: `/api/v1/searches/${searchIteration?.searchId}/iterations/${searchIteration?.id}`,
      withCredentials: true,
      data: {
        name: state.updatedSearchIterationName,
      }
    }).then((response) => {
      if (callbackFn) {
        callbackFn(state.updatedSearchIterationName)
      }
      setSuccess("Version updated successfully")
    }).catch((error) => {
      setError("Error updating version")
    }).finally(() => {
      onClose()
    })
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={() => {
        onClose()
        setState(defaultState)
      }}
    >
      <ModalComponents.ModalPage
        title={`Update "${searchIteration?.name}"`}
        body={
          <TextField
            autoFocus
            value={state.updatedSearchIterationName}
            label="Name"
            sx={{
              marginTop: "1rem",
            }}
            onChange={(e) => {
              setState((state) => {
                state.updatedSearchIterationName = e.target.value
              })
            }}
            fullWidth
          />
        }
        buttons={
          <>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={state.updatedSearchIterationName === searchIteration?.name || state.updatedSearchIterationName.length === 0}
              onClick={() => {
                onSubmit(callbackFn)
                onClose()
              }}
            >
              Update
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  )
}


const EditSearchIterationModalProvider = ({
  children,
}: {
  children: ReactNode,
}) => {
  const defaultState = {
    open: false,
    callbackFn: () => {},
    requestPending: false,
    search: undefined,
    updatedSearchName: ""
  }

  const [state, setState] = useImmer<{
    open: boolean,
    callbackFn: (newName: string) => void
    requestPending: boolean,
    searchIteration?: SearchIteration,
    updatedSearchName: string
  }>(defaultState);

  const onClose = () => {
    setState(defaultState)
  }

  const noneCallbackFn = (newName: string) => {}

  const context = {
    openUpdateSearchIteration: (searchIteration: SearchIteration, callbackFn?: (newName: string) => void) => {
      setState((state) => {
        state.searchIteration = searchIteration
        state.open = true
        state.callbackFn = callbackFn || noneCallbackFn
      })
    }
  }

  return (
    <EditSearchIterationModalContext.Provider value={context}>
      {children}
      <EditSearchIterationModal
        open={state.open}
        searchIteration={state.searchIteration}
        onClose={onClose}
        callbackFn={state.callbackFn}
      />
    </EditSearchIterationModalContext.Provider>
  )
}


export default EditSearchIterationModalProvider