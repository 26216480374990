import axios from "axios";
import ModalComponents from "../Modal";
import * as React from "react";
import {Button} from "@mui/material";
import TruncatedList from "../../pages/watchlists/components/TruncatedList";
import {BookmarkOutlined} from "@mui/icons-material";
import {AlertColor} from "@mui/material/Alert";
import {createContext, useContext} from "react";
import {ToastContext} from "../../providers/ToastProvider";
import {useImmer} from "use-immer";


const AddIntentModal = ({
  open,
  onClose,
  domainNames,
  redirect=true,
  refreshData,
}: {
  open: boolean,
  onClose: () => void,
  domainNames: string[],
  redirect?: boolean
  refreshData?: () => void,
}) => {
  const {setMessage} = useContext(ToastContext)

  const onSubmitTrackIntent = (
    domainNames: string[],
  ) => {
    axios({
      method: "POST",
      url: `/api/v1/intent/batch`,
      data: {
        "domain_names": domainNames,
      },
      withCredentials: true,
    }).then((response) => {
      setMessage(response.data["toast"]["message"], response.data["toast"]["severity"] as AlertColor)
      if (refreshData) {
        refreshData()
      }
      if (redirect) {
        setTimeout(() => {
          window.location.assign("/intent")
        }, 2000)
      }
    }).catch((error) => {
      setMessage(error.response.data.toast.message, error.response.data.toast.severity as AlertColor)
    })
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={onClose}
    >
      <ModalComponents.ModalPage
        title={"Confirmation"}
        body={
          <>
            <h4>Tracking Domains</h4>
            <TruncatedList
              domains={domainNames}
              maxRows={10}
              icon={<BookmarkOutlined color="success" />}
            />
            <ModalComponents.ModalBodyText
              content={`Financial intent will be revealed for ${domainNames.length} companies and credits will be deducted from your account.`}
            />
          </>
        }
        buttons={
          <>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="outlined"
              onClick={() => {
                onSubmitTrackIntent(domainNames)
                onClose()
              }}
            >
              Confirm
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  )
}


export type AddIntentModalContextValue = {
  show: (domains: string[], refreshData?: () => void, redirect?: boolean) => void
};

export const AddIntentModalContext = createContext<AddIntentModalContextValue>({
  show: (domains: string[], refreshData?: () => void, redirect?: boolean) => {}
});

const AddIntentModalProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean,
    onClose: () => void,
    domains: string[],
    refreshData: () => void,
    redirect: boolean,
  }>({
    open: false,
    onClose: () => {},
    domains: [],
    refreshData: () => {},
    redirect: false,
  });

  const context = {
    show: (domains, refreshData?: () => void, redirect?: boolean) => {
      setState((state) => {
        state.domains = domains
        state.refreshData = refreshData || (() => {})
        state.redirect = redirect || false
        state.open = true
      })
    }
  } as AddIntentModalContextValue

  return (
    <AddIntentModalContext.Provider value={context}>
      {children}
      <AddIntentModal
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false
            state.domains = []
          })
        }}
        refreshData={state.refreshData}
        domainNames={state.domains}
        redirect={state.redirect || false}
      />
    </AddIntentModalContext.Provider>
  );
}


export default AddIntentModalProvider