import React, {createContext, useContext} from "react";
import {useImmer} from "use-immer";
import ModalComponents from "../components/Modal";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import axios from "axios";
import {ToastContext} from "./ToastProvider";

export type ReportIssueContextValue = {
  show: (context?: any) => void,
};

export const ReportIssueContext = createContext<ReportIssueContextValue>({
  show: (context?: any) => {},
});

const IssueTypes = [
  "Inaccurate firmographic info",
  "Predictive signals issue",
  "Page functionality issue",
  "Other",
]


const ReportIssueProvider = ({ children }: { children: any }) => {
  const {setSuccess, setError} = useContext(ToastContext)

  const [state, setState] = useImmer<{
    open: boolean,
    issue: string,
    content: string,
    context: any,
    inProgress: boolean
  }>({
    open: false,
    issue: "Other",
    content: "",
    context: {},
    inProgress: false,
  });

  const onClose = () => {
    setState((state) => {
      state.open = false
      state.issue = "Other"
      state.content = ""
      state.context = {}
    })
  }

  const onSubmit = () => {
    setState((state) => {
      state.inProgress = true
    })
    axios({
      method: "POST",
      url: `/api/v1/issues/report`,
      data: {
        issue: state.issue,
        content: state.content,
        context: state.context,
      },
      withCredentials: true,
    }).then((response) => {
      setSuccess("Report submitted, thank you for your feedback!")
      onClose()
    }).catch((error) => {
      setError("Unable to submit report, please reach out to support")
    }).finally(() => {
      setState((state) => {
        state.inProgress = false
      })
    })
  }

  const context = {
    show : (context?: any) => {
      setState((state) => {
        state.open = true
        state.context = context || {}
      })
    }
  } as ReportIssueContextValue

  return (
    <ReportIssueContext.Provider value={context}>
      {children}
      <ModalComponents.ModalContainer
        open={state.open}
        onClose={onClose}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (state.issue && state.content && !state.inProgress) {
              onSubmit()
            }
          }}
        >
          <ModalComponents.ModalPage
            title={"Submit Issue"}
            body={
              <Box
                sx={{
                  paddingTop: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    size="small"
                  >
                    Issue
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      setState((state) => {
                        state.issue = e.target.value
                      })
                    }}
                    label={"Issue"}
                    value={state.issue}
                    size={"small"}
                    required
                    sx={{
                      padding: 0,
                    }}
                  >
                    {
                      IssueTypes.map((e) => (
                        <MenuItem value={e}>{e}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  placeholder={"Provide some context on the issue you are encountering so we can best assist you"}
                  rows={6}
                  multiline
                  fullWidth
                  required
                  onChange={(e) => {
                    setState((state) => {
                      state.content = e.target.value
                    })
                  }}
                />
              </Box>
            }
            buttons={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="stretch"
                width="100%"
                gap="1rem"
              >
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={onClose}
                  sx={{
                    width: "50%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    width: "50%",
                  }}
                  disabled={state.issue.length === 0 || state.content.length === 0 || state.inProgress}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="0.5rem"
                  >
                    Submit
                    {
                      state.inProgress ? <CircularProgress color="inherit" size={16} /> : null
                    }
                  </Box>
                </Button>
              </Box>
            }
          />
        </form>
      </ModalComponents.ModalContainer>
    </ReportIssueContext.Provider>
  );
}

export default ReportIssueProvider
