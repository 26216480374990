import {useImmer} from "use-immer";
import axios, {AxiosError} from "axios";
import {camelize} from "../../utils";
import {createContext, useContext, useEffect} from "react";
import ModalComponents from "../Modal";
import * as React from "react";
import {Autocomplete, Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {ToastContext} from "../../providers/ToastProvider";


const AddScreenerModal = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) => {
  const {setError} = useContext(ToastContext)

  const [state, setState] = useImmer<{
    newScreenerName: string,
    inProgress: boolean,
  }>({
    newScreenerName: "",
    inProgress: false,
  })

  const onSubmit = (
    newScreenerName: string
  ) => {
    setState((state) => {
      state.inProgress = true
    })
    axios({
      method: "POST",
      url: `/api/v1/screeners`,
      data: {
        name: newScreenerName,
      },
      withCredentials: true,
    }).then((response) => {
      const screenerId = response.data.details.screener_id
      console.log(response.data.details.screener_id)
      window.location.assign(`/screeners/${screenerId}`)
    }).catch((reason: AxiosError) => {
      if (reason.response!.status === 403) {
        setError("Reached max number of screener created")
      } else {
        setError("Unable to create screener, please try again")
      }
    }).finally(() => {
      setState((state) => {
        state.inProgress = false
      })
    })
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={onClose}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(state.newScreenerName)
        }}
      >
        <ModalComponents.ModalPage
          title={"New Screener"}
          body={
            <TextField
              autoFocus
              value={state.newScreenerName}
              label="Name"
              sx={{
                marginTop: "1rem",
              }}
              onChange={(e) => {
                setState((state) => {
                  state.newScreenerName = e.target.value
                })
              }}
              fullWidth
            />
          }
          buttons={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="stretch"
              width="100%"
              gap="1rem"
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
                sx={{
                  width: "50%",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="outlined"
                sx={{
                  width: "50%",
                }}
                disabled={state.inProgress}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="0.5rem"
                >
                  Submit
                  {
                    state.inProgress ? <CircularProgress color="inherit" size={16} /> : null
                  }
                </Box>
              </Button>
            </Box>
          }
        />
      </form>
    </ModalComponents.ModalContainer>
  )
}


export type AddScreenerModalContextValue = {
  show: () => void
};

export const AddScreenerModalContext = createContext<AddScreenerModalContextValue>({
  show: () => {}
});


const AddScreenerModalProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean,
    onClose: () => void,
  }>({
    open: false,
    onClose: () => {},
  });

  const context = {
    show: () => {
      setState((state) => {
        state.open = true
      })
    }
  } as AddScreenerModalContextValue

  return (
    <AddScreenerModalContext.Provider value={context}>
      {children}
      <AddScreenerModal
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false
          })
        }}
      />
    </AddScreenerModalContext.Provider>
  );
}


export default AddScreenerModalProvider