import axios from "axios";
import ModalComponents from "../Modal";
import * as React from "react";
import {Autocomplete, Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import TruncatedList from "../../pages/watchlists/components/TruncatedList";
import {ArrowRight, BookmarkOutlined} from "@mui/icons-material";
import {createContext, useContext, useEffect} from "react";
import {ToastContext} from "../../providers/ToastProvider";
import {useImmer} from "use-immer";
import {NewScreenerOption, Option, Screener} from "../../types/search";
import {camelize} from "../../utils";
import {cleanDomains} from "../../pages/watchlists/components/utils";



const Overview = ({
  screeners,
  selectedScreener,
  domainNames,
  setSelectedScreener,
  newScreenerName,
  setNewScreenerName,
  phase,
  setNextPhase,
  onClose,
}: {
  screeners: Screener[],
  selectedScreener: Option,
  domainNames: string[],
  setSelectedScreener: (list: Option) => void
  newScreenerName: string,
  setNewScreenerName: (name: string) => void
  phase: number,
  setNextPhase: (phase: number, redirectScreenerId: string) => void,
  onClose: () => void
}) => {
  const {setSuccess, setError} = useContext(ToastContext)

  const [state, setState] = useImmer<{
    locked: boolean,
  }>({
    locked: false,
  })

  const onSubmit = (
    screenerId: string,
    newScreenerName: string,
    domainNames: string[],
    phase: number,
    setNextPhase: (phase: number, redirectListId: string) => void,
  ) => {
    setState((state) => {
      state.locked = true
    })
    if (newScreenerName) {
      axios({
        method: "POST",
        url: `/api/v1/screeners`,
        data: {
          name: newScreenerName,
          domain_names: cleanDomains(domainNames),
        },
        withCredentials: true,
      }).then((response) => {
        const redirectScreenerId = camelize(response.data).details.screenerId
        setNextPhase(phase + 1, redirectScreenerId)
      }).catch((error) => {
        setError("Unable to create screener")
      }).finally(() => {
        setState((state) => {
          state.locked = false
        })
      })
    } else {
      axios({
        method: "POST",
        url: `/api/v1/screeners/${screenerId}/entries`,
        data: {
          domain_names: cleanDomains(domainNames),
          unmatched: [],
        },
        withCredentials: true,
      }).then((response) => {
        setNextPhase(phase + 1, screenerId)
      }).catch((error) => {
        setError("Unable to add to screener")
      }).finally(() => {
        setState((state) => {
          state.locked = false
        })
      })
    }
  }

  return (
    <ModalComponents.ModalPage
      title={"Add to Screener"}
      body={
        <>
          <Box
            sx={{
              padding: "1rem 0"
            }}
          >
            <Autocomplete
              size="small"
              options={[
                NewScreenerOption,
                ...([...screeners].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                  .map((l) => ({
                    id: l.id,
                    label: l.name,
                  })))
              ]}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option: Option, value: Option) => {
                return option.id === value.id
              }}
              disableClearable
              value={selectedScreener}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
              onChange={(e, value: Option) => {
                setSelectedScreener(value)
              }}
            />
            {
              selectedScreener.label === NewScreenerOption.label ? (
                <Box
                  sx={{
                    paddingTop: "0.5rem"
                  }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    label="New Screener Name"
                    required
                    fullWidth
                    onChange={(e) => {
                      setNewScreenerName(e.target.value)
                    }}
                  />
                </Box>
              ): null
            }
          </Box>
          <Box>
            <Typography variant="h6" component="h4">
              Domains
            </Typography>
            <TruncatedList
              domains={domainNames}
              maxRows={8}
              icon={<ArrowRight color="success" />}
            />
          </Box>
        </>
      }
      buttons={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          width="100%"
          gap="1rem"
        >
          <Button
            color="primary"
            variant="outlined"
            sx={{
              width: "50%",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            sx={{
              width: "50%",
            }}
            onClick={() => {
              onSubmit(
                selectedScreener.id,
                newScreenerName,
                domainNames,
                phase,
                setNextPhase,
              )
            }}
            disabled={state.locked || (selectedScreener.id === NewScreenerOption.id && newScreenerName.length === 0)}
            endIcon={state.locked ? <CircularProgress color="inherit" size={16} /> : null}
          >
            Submit
          </Button>
        </Box>
      }
    />
  )
}

const Success = ({
  domainNames,
  newListName,
  selectedList,
  redirectListId,
  onClose,
}: {
  domainNames: string[],
  newListName: string,
  selectedList: Option,
  redirectListId: string,
  onClose: () => void,
}) => {
  const title = newListName.length > 0 ? `new screener '${newListName}'` : `screener '${selectedList.label}'`
  const content = `Added ${domainNames.length} domains to ${title}`
  return (
    <ModalComponents.ModalPage
      title={"Success"}
      body={
        <ModalComponents.ModalBodyText
          content={content}
        />
      }
      buttons={
        <>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            OK
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            href={`/screeners/${redirectListId}`}
          >
            Go to Screener
          </Button>
        </>
      }
    />
  )
}

const AddToScreenerModal = ({
  open,
  onClose,
  domainNames,
  redirect=true,
  refreshData,
}: {
  open: boolean,
  onClose: () => void,
  domainNames: string[],
  redirect?: boolean
  refreshData?: () => void,
}) => {
  const [state, setState] = useImmer<{
    loaded: boolean,
    selectedScreener: Option,
    newScreenerName: string,
    screeners: Screener[],
    redirectScreenerId: string,
    phase: number,
  }>({
    loaded: false,
    selectedScreener: NewScreenerOption,
    newScreenerName: "",
    screeners: [],
    redirectScreenerId: "",
    phase: 0,
  })

  const initialize = () => {
    axios({
      method: "GET",
      url: `/api/v1/screeners`,
      withCredentials: true,
    }).then((response) => {
      setState((state) => {
        state.screeners = camelize(response.data).screeners as Screener[]
        state.loaded = true
      })
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (!state.loaded) {
      initialize();
    }
  });

  const resetAndClose = () => {
    setState((state) => {
      state.phase = 0
    })
    onClose()
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={resetAndClose}
    >
      {
        state.phase === 0 ? (
          <Overview
            screeners={state.screeners}
            selectedScreener={state.selectedScreener}
            domainNames={domainNames}
            setSelectedScreener={(value: Option) => {
              setState((state) => {
                state.selectedScreener = value
              })
            }}
            newScreenerName={state.newScreenerName}
            setNewScreenerName={(name: string) => {
              setState((state) => {
                state.newScreenerName = name
              })
            }}
            phase={state.phase}
            setNextPhase={(phase: number, redirectScreenerId: string) => {
              setState((state) => {
                state.phase += 1
                state.redirectScreenerId = redirectScreenerId
              })
            }}
            onClose={resetAndClose}
          />
        ) : (
          <Success
            domainNames={domainNames}
            newListName={state.newScreenerName}
            selectedList={state.selectedScreener}
            redirectListId={state.redirectScreenerId}
            onClose={resetAndClose}
          />
        )
      }
    </ModalComponents.ModalContainer>
  )
}


export type AddToScreenerModalContextValue = {
  show: (domains: string[], refreshData?: () => void, redirect?: boolean) => void
};

export const AddToScreenerModalContext = createContext<AddToScreenerModalContextValue>({
  show: (domains: string[], refreshData?: () => void, redirect?: boolean) => {}
});

const AddToScreenerModalProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean,
    onClose: () => void,
    domains: string[],
    refreshData: () => void,
    redirect: boolean,
  }>({
    open: false,
    onClose: () => {},
    domains: [],
    refreshData: () => {},
    redirect: false,
  });

  const context = {
    show: (domains, refreshData?: () => void, redirect?: boolean) => {
      setState((state) => {
        state.domains = domains
        state.refreshData = refreshData || (() => {})
        state.redirect = redirect || false
        state.open = true
      })
    }
  } as AddToScreenerModalContextValue

  return (
    <AddToScreenerModalContext.Provider value={context}>
      {children}
      <AddToScreenerModal
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false
            state.domains = []
          })
        }}
        refreshData={state.refreshData}
        domainNames={state.domains}
        redirect={state.redirect || false}
      />
    </AddToScreenerModalContext.Provider>
  );
}


export default AddToScreenerModalProvider