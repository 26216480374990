import {useImmer} from "use-immer";
import axios from "axios";
import {createContext, useContext, useEffect} from "react";
import ModalComponents from "../Modal";
import * as React from "react";
import {Box, Button, CircularProgress, TextField} from "@mui/material";
import {ToastContext} from "../../providers/ToastProvider";
import {Screener} from "../../types/search";


const DuplicateScreenerModal = ({
  screener,
  open,
  onClose,
}: {
  screener?: Screener,
  open: boolean,
  onClose: () => void,
}) => {
  const {setError} = useContext(ToastContext)

  const [state, setState] = useImmer<{
    newScreenerName: string,
    inProgress: boolean,
  }>({
    newScreenerName: screener ? `${screener.name} - copy` : "",
    inProgress: false,
  })

  useEffect(() => {
    setState((state) => {
      state.newScreenerName = screener ? `${screener.name} - copy` : ""
    })
  }, [screener])

  const onSubmit = (
    screenerId: string,
    newScreenerName: string,
  ) => {
    setState((state) => {
      state.inProgress = true
    })
    axios({
      method: "POST",
      url: `/api/v1/screeners`,
      data: {
        name: newScreenerName,
      },
      withCredentials: true,
    }).then((response) => {
      const newScreenerId = response.data.details.screener_id
      window.location.assign(`/screeners/${newScreenerId}?from=${screenerId}`)
    }).catch((error) => {
      setError("Unable to duplicate screener, please try again")
    }).finally(() => {
      setState((state) => {
        state.inProgress = false
      })
    })
  }

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={onClose}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(screener!.id, state.newScreenerName)
        }}
      >
        <ModalComponents.ModalPage
          title={"Duplicate Screener"}
          body={
            <TextField
              autoFocus
              value={state.newScreenerName}
              label="Name"
              sx={{
                marginTop: "1rem",
              }}
              onChange={(e) => {
                setState((state) => {
                  state.newScreenerName = e.target.value
                })
              }}
              fullWidth
            />
          }
          buttons={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="stretch"
              width="100%"
              gap="1rem"
            >
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={onClose}
                sx={{
                  width: "50%",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="outlined"
                sx={{
                  width: "50%",
                }}
                disabled={state.inProgress}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="0.5rem"
                >
                  Submit
                  {
                    state.inProgress ? <CircularProgress color="inherit" size={16} /> : null
                  }
                </Box>
              </Button>
            </Box>
          }
        />
      </form>
    </ModalComponents.ModalContainer>
  )
}


export type DuplicateScreenerModalContextValue = {
  show: (screener: Screener) => void
};

export const DuplicateScreenerModalContext = createContext<DuplicateScreenerModalContextValue>({
  show: (screener: Screener) => {}
});


const DuplicateScreenerModalProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean,
    onClose: () => void,
    screener?: Screener,
  }>({
    open: false,
    onClose: () => {},
    screener: undefined,
  });

  const context = {
    show: (screener: Screener) => {
      setState((state) => {
        state.open = true
        state.screener = screener
      })
    }
  } as DuplicateScreenerModalContextValue

  return (
    <DuplicateScreenerModalContext.Provider value={context}>
      {children}
      <DuplicateScreenerModal
        screener={state.screener}
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false
          })
        }}
      />
    </DuplicateScreenerModalContext.Provider>
  );
}


export default DuplicateScreenerModalProvider