import React, {createContext, ReactNode, useContext, useEffect} from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import { SessionContext } from "../providers/SessionProvider";
import {
  Alert,
  AppBar, Box,
  Button, CircularProgress, CSSObject, Divider,
  Drawer as MuiDrawer,
  IconButton, InputAdornment,
  List,
  ListItem,
  ListItemButton, ListItemIcon, ListItemText,
  Paper, styled, Theme,
  Toolbar,
  Typography
} from "@mui/material";
import {AlertColor} from "@mui/material/Alert";
import {selectAlert} from "../slices";
import {useSelector} from "react-redux";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import {SearchContext} from "../providers/SearchProvider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {PageHeaderContext} from "../providers/PageHeaderProvider";
import {useImmer} from "use-immer";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchBar from "./SearchBar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {UserSettingsContext} from "../providers/UserSettings";
import GroupIcon from '@mui/icons-material/Group';
import {PagesWithNavHidden} from "../constants";
import {useMediaQueryContext} from "../providers/MediaQueryProvider";


const AppBarHeader = () => {
  const pageHeader = useContext(PageHeaderContext);
  const userSettings = useContext(UserSettingsContext);
  const { isLowRes, isMediumRes } = useMediaQueryContext()

  const [state, setState] = useImmer<{
    scrollPastHeader: boolean,
  }>({
    scrollPastHeader: false,
  })

  useEffect(() => {
    const onScroll = () => {
      setState((state) => {
        state.scrollPastHeader = window.scrollY > 5 * 16
      })
    };
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const backButton = pageHeader.onBack
    ? (
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          pageHeader.onBack?.()
        }}
      >
        <ChevronLeftIcon />
      </IconButton>
    )
    : null

  const subtitleHeader = pageHeader.subtitle
    ? (
      <div className={classNames(styles.header, {
        [styles.expanded]: userSettings.menuExpanded,
      })}>
        <Typography
          variant="h5"
          style={{
            margin: "1.2rem 0",
            fontWeight: 525,
          }}
        >
          {pageHeader.subtitle}
        </Typography>
      </div>
    ) : null

  return (
    pageHeader.loaded ? (
      <>
        <Paper
          className={classNames(styles.header, {
            [styles.sticky]: pageHeader.sticky,
            [styles.expanded]: userSettings.menuExpanded,
          })}
          elevation={state.scrollPastHeader && pageHeader.sticky ? 1 : 0}
          sx={{
            "--offset": `${5 + (pageHeader.isSet ? 5 : 0)}rem`,
          }}
        >
          <div
            className={styles.container}
            style={{
              paddingTop: "2rem",
            }}
          >
            <div className={styles.title}>
              {backButton}
              <Typography
                variant="h4"
                style={{
                  margin: "0.75rem 0",
                  fontWeight: "bold",
                  fontSize: isMediumRes ? "calc(2.125rem * 0.8)" : isLowRes ? "1.688" : "2.125rem",
                }}
              >
                {pageHeader.title}
              </Typography>
              {pageHeader.help}
            </div>
            <div>
              {pageHeader.children}
            </div>
          </div>
        </Paper>
        {subtitleHeader}
      </>
    ) : null
  )
}


const Nav = () => {
  const session = useContext(SessionContext);
  const search = useContext(SearchContext);
  const userSettings = useContext(UserSettingsContext);
  const pageHeader = useContext(PageHeaderContext);

  const [state, setState] = useImmer<{
    searchInProgress: boolean,
    keywords: string,
  }>({
    searchInProgress: false,
    keywords: "",
  })

  const onLogout = () => {
    pageHeader.setPageHeader("", "")
    session.logout();
    window.location.assign("/login");
  };

  useEffect(() => {
    const handleSlashKeydown = (event: any) => {
      // if (event.key === '/') {
      //   search.onOpen()
      //   event.preventDefault();
      // }
    };
    window.addEventListener('keydown', handleSlashKeydown);

    return () => {
      window.removeEventListener('keydown', handleSlashKeydown);
    };
  }, []);

  const barContent = session.exists ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        className={styles.hiddenOnMobile}
        style={{
          color: "white",
          whiteSpace: "nowrap",
        }}
      >
        {session.user?.email}
      </div>
      <Box
        className={styles.hiddenOnMobile}
        sx={{
          color: "white",
        }}
      >
        <IconButton
          href="/accounts"
          sx={{
            color: "inherit",
          }}
        >
          <SettingsIcon />
        </IconButton>
      </Box>
      <div className={styles.hiddenOnMobile}>
        <IconButton
          onClick={onLogout}
          sx={{
            color: "white",
          }}
        >
          <LogoutIcon color={"inherit"} />
        </IconButton>
      </div>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "80%",
        margin: "auto",
      }}
    >
      <img
        src={"/fintent-small-notext-transparent.png"}
        style={{
          maxHeight: "3rem",
        }}
      />
      <div className={classNames(styles.link, styles.hiddenOnMobile)}>
        <a
          href="/login"
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          Login
        </a>
      </div>
    </Box>
  );

  const NavItem = ({
    expanded,
    primary,
    icon,
    href,
    onClick,
    active = false,
    enabled = true,
  }: {
    expanded: boolean,
    primary: string,
    icon: ReactNode,
    href?: string,
    onClick?: () => void,
    active?: boolean,
    enabled?: boolean,
  }) => {
    if (!enabled) {
      return null
    }
    return (
      <ListItem
        disablePadding
        sx={{
          display: 'block',
          color: active ? "white" : "inherit",
        }}
        component="a"
        href={href}
        onClick={onClick}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: expanded ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: expanded ? 3 : 'auto',
              justifyContent: 'center',
              color: "inherit",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={primary}
            sx={{
              opacity: expanded ? 1 : 0,
            }}
          />
        </ListItemButton>
      </ListItem>
    )
  }

  const drawerWidth = 260;

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  const onClear = () => {
    setState((state) => {
      state.keywords = ""
    })
  }

  const endAdornment = (
    state.searchInProgress ? (
      <InputAdornment position="end">
        <CircularProgress color="inherit" size={16} />
      </InputAdornment>
    ) : state.keywords.length > 0 ? (
      <InputAdornment position="end">
        <IconButton onClick={onClear}>
          <CloseOutlinedIcon />
        </IconButton>
      </InputAdornment>
    ) : null
  )

  return (
    <>
      <Toolbar
        className={styles.nav}
        disableGutters
      >
        <Paper
          className={classNames(styles.header, styles.nav, styles.black, {
            [styles.expanded]: userSettings.menuExpanded,
            [styles.hideNav]: PagesWithNavHidden.includes(window.location.pathname),
          })}
          sx={{
            width: "100%",
            gap: "1rem",
            borderRadius: 0,
          }}
          elevation={1}
        >
          <SearchBar
            id="global-search"
            value={state.keywords}
            placeholder="Search for company by domain"
            onSearch={(keywords) => {
              setState((state) => {
                state.keywords = keywords
              })
              search.onSearch({
                keywords: keywords
              })
            }}
            onClear={onClear}
            inputProps={{
              startAdornment: (
                <SearchIcon
                  sx={{
                    marginRight: "0.5rem",
                  }}
                />
              ),
              endAdornment: endAdornment,
              sx: {
                backgroundColor: "white",
              }
            }}
            style={{
              marginRight: "auto",
              width: "80%",
              display: session.exists ? "inherit" : "none"
            }}
          />
          {barContent}
        </Paper>
      </Toolbar>
      <div>
        {
          session.exists ? (
            <Drawer
              variant={"permanent"}
              anchor={"left"}
              open={userSettings.menuExpanded}
              onClose={() => {}}
              PaperProps={{
                sx: {
                  backgroundColor: "black",
                }
              }}
            >
              <Box
                sx={{
                  backgroundColor: "black",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    paddingLeft: userSettings.menuExpanded ? "1.25rem" : "inherit",
                    height: "6rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: userSettings.menuExpanded ? "inherit" : "center",
                  }}
                >
                  <a href={"/"}>
                    {
                      userSettings.menuExpanded ? (
                        <img
                          src={"/fintent-logo-color-whitetext-300x103.png"}
                          style={{
                            maxHeight: "3rem",
                          }}
                        />
                      ) : (
                        <img
                          src={"/fintent-small-notext-transparent.png"}
                          style={{
                            maxHeight: "3rem",
                          }}
                        />
                      )
                    }
                  </a>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "black",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <List
                    sx={{
                      color: "darkgray",
                      padding: "1rem 0",
                    }}
                  >
                    <NavItem
                      primary={"Predictive Signals"}
                      expanded={userSettings.menuExpanded}
                      icon={<LeaderboardIcon />}
                      href={"/screeners"}
                      active={window.location.pathname.startsWith("/screeners")}
                    />
                    <NavItem
                      primary={"Company Lists"}
                      expanded={userSettings.menuExpanded}
                      icon={<CreateNewFolderIcon />}
                      href={"/watchlists"}
                      active={window.location.pathname.startsWith("/watchlists")}
                    />
                    <NavItem
                      primary={"Advanced Search"}
                      expanded={userSettings.menuExpanded}
                      icon={<SearchIcon />}
                      href={"/searches"}
                      active={window.location.pathname.startsWith("/searches")}
                    />
                    <NavItem
                      enabled={session.exists && session.user?.isAdmin}
                      primary={"Users"}
                      expanded={userSettings.menuExpanded}
                      icon={<GroupIcon />}
                      href={"/users"}
                      active={window.location.pathname === "/users"}
                    />
                  </List>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: userSettings.menuExpanded ? "right" : "center",
                      paddingRight: userSettings.menuExpanded ? "1rem" : "inherit",
                      paddingBottom: "1rem",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        if (userSettings.menuExpanded) {
                          userSettings.setMenuExpanded(false)
                        } else {
                          userSettings.setMenuExpanded(true)
                        }
                      }}
                      sx={{
                        color: "darkgray",
                        border: "1px solid darkgray",
                        borderRadius: "0.25rem",
                      }}
                    >
                      {
                        userSettings.menuExpanded ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      }
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Drawer>
          ) : null
        }
      </div>
      <AppBarHeader />
    </>
  );
};

export default Nav
